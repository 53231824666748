@import '../../variables';

.confirm-button {
	display: block;

	margin: $padding;
	padding: $padding;

	background-color: $dark;
	color: $light;
	border: 1px solid $dark;
	border-radius: 11px;

	font-size: 120%;
	text-align: center;
	text-decoration: none;
	font-weight: bold;
}

body .confirm-button.disabled {
	pointer-events: none;
	background-color: $dark-gray;
	border-color: $dark-gray;
}

.sura .confirm-button {
	background-color: $sura_primary;
	border: 1px solid $sura_primary;
}

.csm .confirm-button {
	background-color: $csm_primary;
	border: 1px solid $csm_primary;
}

.lafise .confirm-button {
	background-color: $lafise_primary;
	border: 1px solid $lafise_primary;
}

.oceanica .confirm-button {
	background-color: $oceanica_secondary;
	border: 1px solid $oceanica_secondary;
}

.qualitas .confirm-button {
	background-color: $qualitas_primary;
	border: 1px solid $qualitas_primary;
}

.assa .confirm-button {
	&.disabled {
		background-color: $assa_inactive_bg;
		border: 1px solid $assa_inactive_bg;
		color: $assa_inactive_text;
	}
	background-color: $assa_primary;
	border: 1px solid $assa_primary;
}
