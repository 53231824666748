@import '../../variables';

.vehicle-damages {
	display: flex;
	height: 100%;

	.damages-container {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;

		img {
			width: 90%;
		}

		.damages-checks {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
			padding: $padding;

			.flex-fix {
				margin: 0px 6px;
				height: 0;
				width: 32px;
			}
		}
	}
}
