@import '../../../assets//styles/variables';

  .button {
    display: block;
    background-color: $kinda-white;
    color: $dark;
    border: 1px solid $dark;
    border-radius: 11px;
  
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    width: 100%;
  }

  .oceanica {
    background-color: $oceanica_secondary;
    color: $light;
    border: none;
  }

  .qualitas {
    background-color: $qualitas_primary;
    color: $light;
    border: none;
  }

  .lafise {
    background-color: $lafise_primary;
    color: $light;
    border: none;
  }

  .csm {
    background-color: $csm_primary;
    color: $light;
    border: none;
  }

  .sura {
    background-color: $sura_primary;
    color: $light;
    border: none;
  }

  .danger {
    background-color: $danger;
    color: $kinda-white;
  }