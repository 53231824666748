@import '../../_variables.scss';

.loading {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.9);
	top: 0;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: $kinda-white;
}
