@import '../../../assets/styles/_variables';

.video-section-screen {
  .photos-scrollable-area {
    padding: 10px;
    padding-top: 0;
  }

  .video-instructions {
    margin-top: 0;
  }

  .custom-file-upload {
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
    color: $dark-grey;
    background-color: transparent;
    height: 50px;
    border: 1px solid $light-gray;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    svg {
      height: 100%;
      margin-right: 15px;
    }
  }

  .video-processed {
    margin: 1rem 0;
  }

  .info-alert.MuiAlert-root {
    color: #2e5aac;
    border: 1px solid #89a7e0;
    margin-bottom: 1rem;

    .MuiAlert-icon {
      color: #2e5aac;
    }

    &.MuiAlert-standardWarning {
      color: $warning-color;
      border: 1px solid $warning-border;
      background-color: $warning-bg;

      .MuiAlert-icon {
        color: $warning-color;
      }
    }

    &.MuiAlert-standardSuccess {
      color: $success-color;
      border: 1px solid $success-border;
      background-color: $success-bg;

      .MuiAlert-icon {
        color: $success-color;
      }
    }
  }
}

#size-alert .MuiAlert-message {
  margin-bottom: 0px;
}

.photo-section-screen {
  height: 100%;
  display: flex;
  flex-direction: column;

  .container {
    height: 100%;
    max-height: calc(100% - 42px - 32px);
  }

  h3.lead {
    display: flex;
    align-items: center;

    // Setting these so as to be predictable
    margin-top: 15px;
    line-height: 22px;
    margin-bottom: 35px;
    margin-left: 15px;
    margin-right: 15px;

    svg {
      height: 20px;
      margin-right: 15px;
    }

    span {
      margin-right: 15px;
    }
  }

  .photos-scrollable-area {
    width: 95%;

    // The 42px and 32px are for the continue button
    max-height: calc(100% - #{15px} - #{22px} - #{35px});
    overflow: scroll;

    display: flex;
    align-items: center;
    flex-direction: column;

    input[type='file'] {
      display: none;
    }
  }

  .photos-container {
    flex: 1;
    max-width: 2 * 170px;
    padding: 10px;

    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .full-screen-preview {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #000000dd;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 2;

    img {
      width: 100%;
      max-height: 80%;
    }

    .action-buttons {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      gap: 20px;
    }
  }
}
