@import '../../variables';

.signature-container {
	.preview,
	.sig-canvas {
		border-bottom: 2px solid $light-gray;
	}

	.preview {
		height: 100px;

		// so that .edit-pen and icons be absolute with respect to this.
		position: relative;
	}

	$icon-padding: 10px;
	.edit-icon {
		width: 20px;
		height: 20px;
		position: absolute;
		top: $icon-padding;
		left: $icon-padding;
		transform: scaleX(-1);
	}
	.clear-icon {
		width: 20px;
		height: 20px;
		position: absolute;
		top: $icon-padding;
		right: $icon-padding;
	}

	.fullscreen-container {
		position: fixed;
		top: 0;
		left: 0;
		background: white;
		height: 100%;
		width: 100%;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.rotate-icon {
			width: 100px;
			height: 100px;
		}

		.canvas-container {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			.help-text {
				position: absolute;

				height: 18px;
				width: 200px;
				top: calc(50% - 9px);
				left: calc(50% - 100px);
				text-align: center;
				color: $dark-gray;
			}
		}

		.confirm-button {
			width: 400px;
		}

		@media screen and (orientation: portrait) {
			.canvas-container,
			.confirm-button {
				display: none;
			}
		}
		@media screen and (orientation: landscape) {
			h2,
			.rotate-icon {
				display: none;
			}
		}
	}
}
