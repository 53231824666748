@import '../../variables';

.selectedArea {
	margin: 0.7rem 0rem 0;
	border: 1px solid #afafaf;
	color: #666;
	background-color: #fbfbfb;
	border-radius: 6px;

	box-sizing: border-box;
	height: 56px;
	display: flex;
	justify-content: center;
	align-items: center;

	-webkit-transition: all 0.1s ease;
	transition: all 0.1s ease;

	&__active {
		border: 2px solid $primary;
		background-color: $primary;
		font-weight: 700;
		color: white;
	}

	&__disclaimer {
		background-color: $beeswax;
		color: $scorpion;
		display: flex;
		align-items: center;
		padding: 8px 8px;
		border-radius: 8px;
		box-shadow: 0 2px 9px 0 rgba(147, 147, 147, 0.5);
	}

	&__disclaimerIcon {
		fill: $scorpion;
		margin-right: 6px;
		min-width: 20px;
		height: 20px;
	}

	&__disclaimerText {
		font-size: 13.5px;
		text-align: center;
	}
}

.sura .selectedArea {
	&__active {
		border: 2px solid $sura_primary;
		background-color: $sura_primary;
	}
}

.csm .selectedArea {
	&__active {
		border: 2px solid $csm_primary;
		background-color: $csm_primary;
	}
}

.lafise .selectedArea {
	&__active {
		border: 3px solid $lafise_primary;
		background-color: $lafise_primary;
	}
}

.oceanica .selectedArea {
	&__active {
		border: 3px solid $oceanica_primary;
		background-color: $oceanica_primary;
	}
}

.qualitas .selectedArea {
	&__active {
		border: 3px solid $qualitas_primary;
		background-color: $qualitas_primary;
	}
}

.assa .selectedArea {
	&__active {
		border: 3px solid $assa_primary;
		background-color: $assa_primary;
	}

	&__disclaimer {
		background-color: $assa_secondary_light;
	}

	&__disclaimerIcon {
		fill: white;
		padding: 5px;
		border-radius: 50px;
		background-color: $assa_primary_light;
	}

	&__disclaimerText {
		font-size: 13.5px;
		text-align: center;
	}
}
