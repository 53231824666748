@import '../../variables';

.fab {
	height: 35px;
	background-color: $whatsapp;
	border-radius: 20px;
	margin-top: 5px;
	img {
		height: inherit;
		width: inherit;
		filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
	}
}
