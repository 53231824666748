@import './fonts';

// Get colors name page: https://chir.ag/projects/name-that-color/#6195ED

$app-font: 'Trade Gothic', Helvetica, Arial, sans-serif;

$danger: #c30909;
$default: #000000;
$dark-grey: #545454;
$dark-gray: #979797;
$gray: #cbcbcb;
$alt-gray: #adadad;
$light-gray: #e7ebec;
$kinda-white: #fbfbfb;
$beeswax: #fdf4c2;
$scorpion: #585858;
$fern: #62c06a;

$light: #ffffff;
$dark: #000000;

$font-color: $dark;
$font-size: 16px; //minimum size to prevent mobile browsers input zoom.
$padding: 16px;
$padding-lg: calc(#{$padding} * 3);

$primary: #e6702a;
$thumbnailSelected: #e6702a;

$sura_primary: #00339f;
$sura_thumbnailSelected: #2758eb;

$csm_backgroundColor: #e7f0f3;
$csm_primary: #00718f;
$csm_thumbnailSelected: #00718f;

$lafise_primary: #008866;
$lafise_thumbnailSelected: #00583c;
$lafise_secondary: #007456;

$oceanica_primary: #0f2a51;
$oceanica_thumbnailSelected: #0d2852;
$oceanica_secondary: #0d2852;

$qualitas_primary: #941b80;
$qualitas_secondary: #0096ae;

$assa_primary: #084a79;
$assa_primary_light: #2f80ed;
$assa_secondary: #00aced;
$assa_secondary_light: #d5e6fb;
$assa_inactive_text: #cecece;
$assa_inactive_bg: #e3e3e3;
$assa_text: #545454;
$assa_bg: #f3f3f3;

$warning-border: #ff8f39;
$warning-bg: #fff4ec;
$warning-color: #b95000;

$success-border: #5aca75;
$success-bg: #edf9f0;
$success-color: #287d3c;

$whatsapp: #7acb6d;
