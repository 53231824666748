@import '../../_variables';

.message-window-container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba($dark, 0.7);
	z-index: 50 !important;

	.message-window {
		min-height: 300px;
		position: relative;
		top: 50%;
		transform: translateY(-50%);

		.container {
			-webkit-box-shadow: 0px 0px 38px -18px rgba(0, 0, 0, 0.75);
			-moz-box-shadow: 0px 0px 38px -18px rgba(0, 0, 0, 0.75);
			box-shadow: 0px 0px 38px -18px rgba(0, 0, 0, 0.75);
			text-align: center;
			margin: 0 $padding-lg;
			padding: $padding; // important to prevent override from a parent component
			background-color: $light;
			display: flex;
			height: auto;
			flex-direction: column;

			img.close {
				align-self: flex-end;
				width: 20px;
				height: 20px;
				padding-left: 10px; // this paddings are to give a litle bit more of clicking area while making the button smaller.
				padding-bottom: 10px;
			}

			h4,
			h5 {
				margin: 0;
				margin-bottom: 3px;
			}
			h4 {
				font-size: 105%;
			}
			h5 {
				font-size: 90%;
			}
			p {
				margin: 16px 0;
				font-size: 85%;
			}

			.default {
				justify-content: center;
				display: flex;
				flex-direction: column;
				flex: 1;
				.body {
					justify-content: center;
					display: flex;
					flex-direction: column;
					flex: 1;
				}
			}
			.buttons-container {
				margin-top: $padding;
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				button.sm {
					font-size: 100%;
					padding: 6px 0;
					margin-bottom: 10px;
					width: 100%;
				}
				button.remove {
					background-color: $danger;
					border-color: $danger;
				}
			}
		}
	}
}

.hidden {
	display: none;
}
