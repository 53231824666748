@import '../../assets//styles/variables';

.app {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
}

.csm * {
	font-family: Frutiger, Helvetica, Arial, sans-serif;
}
