@import '../variables';


.breadcrumbs-container {
	width: 100%;
}


.breadcrumbs {
	background-color: $light-gray;
	padding: calc(22px * 0.9);
	text-align: center;
	position: relative;
	border-bottom-right-radius: 15px;
	border-bottom-left-radius: 15px;
	border-bottom: 2px solid $gray;
	border-right: 2px solid $gray;
	border-left: 2px solid $gray;

	.backChevron {
		float: left;
		height: 17px;
	}
	span {
		margin-left: -10px; // Substract the back button width in order to center the text in the div. It's ugly, I know, but works.
		font-weight: bold;
		color: $dark;
	}
}

.csm {
	.header-logo {
		text-align: center;
		width: 100%;
		background-color: $csm_backgroundColor;
		img {
			padding-top: 5px;
			height: 38px;
		}
	}
	.breadcrumbs-container {
		background-color: $csm_backgroundColor;
		width: 100%;
		.breadcrumbs {
			background-color: $light;
		}
	}
}
