@import '../../variables';

$subtitle-margin-top: 6px;

.responsibility-screen {
	display: flex;
	flex-direction: column;
	height: 100%;

	.container {
		padding: $padding;
		padding-top: 0;
		flex-grow: 1;

		.text-input {
			width: 60%;
			input {
				background-color: $light-gray;
				border: none;
			}
		}
	}
	.header {
		padding-bottom: 0;
	}

	h3,
	p {
		margin: 0;
	}

	p {
		margin-top: $subtitle-margin-top;
	}

	.responsible-options {
		margin-top: $padding;

		button {
			display: block;
			margin: 0px 0px 20px 0px;
			padding: 8px 20px;
		}
	}

	.fud-thumbnail {
		height: 100px;
		width: 100px;
		background: $light-gray;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: $padding 0;

		div {
			margin-top: 6px;
		}
	}

	.legal-assistance-options {
		display: flex;
		margin-top: $padding;
		button {
			width: 60px;
			margin-right: 10px;
			margin-bottom: ($padding - $subtitle-margin-top);
			padding: 8px 20px;
		}
	}
}

.lafise .responsibility-screen {
	.container {
		.text-input {
			width: 100%;
			input {
				background-color: #fbfbfb;
				border: 1px solid #979797;
			}
			input:focus {
				border-color: $lafise_primary;
			}
		}
	}
}

.oceanica .responsibility-screen {
	.container {
		.text-input {
			width: 100%;
			input {
				background-color: #fbfbfb;
				border: 1px solid #979797;
			}
			input:focus {
				border-color: $oceanica_primary;
			}
		}
	}
}

.qualitas .responsibility-screen {
	.container {
		.text-input {
			width: 100%;
			input {
				background-color: #fbfbfb;
				border: 1px solid #979797;
			}
			input:focus {
				border-color: $oceanica_primary;
			}
		}
	}
}