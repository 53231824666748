@import '../../../assets//styles//variables';

.mainContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.photoSectionContainer {
	display: flex;
	flex-direction: column;
	flex: 1 1;
}

.photoSectionInfo {
	height: 100%;
	margin: 2em;
	color: $csm_primary;
	text-align: center;

	img {
		margin-top: 50px;
		margin-bottom: 50px;
	}

	p:first-child {
		font-weight: bold;
		font-size: 20px;
	}

	p {
		font-size: 18px;
		span {
			font-weight: bold;
			margin: 5px;
		}
	}
}

.photoSection {
	&__container {
		margin: 1rem;
		justify-content: space-between;
	}

	&__button {
		background-color: $kinda-white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 1rem;
		border-radius: 11px;
		text-decoration: none;
		height: 4rem;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		font-weight: bold;
		position: relative;
		border: 1px solid $dark-grey;
		color: $dark-grey;

		img {
			width: 13%;
		}
	}
}

.container {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
}

.warning {
	color: $danger;
	margin: 0 16px;
	font-size: 90%;
}

.icon {
	height: 3rem;
	stroke: $dark-grey;
	fill: $dark-grey;
}

.exclamationMark {
	height: 2rem;
	position: absolute;
	top: -1rem;
	left: -1rem;
	fill: $light;
	background-color: $danger;
	border-radius: 50%;
}

.checkmark {
	height: 2rem;
	position: absolute;
	top: -1rem;
	left: -1rem;
	stroke: $light;
	background-color: $fern;
	border-radius: 50%;
}

.oceanica {
	border: 4px solid $oceanica_secondary;
	color: $oceanica_secondary;

	&__icon {
		stroke: $oceanica_secondary;
		fill: $oceanica_secondary;
	}
}

.qualitas {
	border: 4px solid $qualitas_primary;
	color: $qualitas_primary;

	&__icon {
		stroke: $qualitas_primary;
		fill: $qualitas_primary;
	}
}

.lafise {
	border: 4px solid $lafise_primary;
	color: $lafise_primary;

	&__icon {
		stroke: $lafise_primary;
		fill: $lafise_primary;
	}
}

.csm {
	border: 4px solid $csm_primary;
	color: $csm_primary;

	&__icon {
		stroke: $csm_primary;
		fill: $csm_primary;
	}
}

.sura {
	border: 4px solid $sura_primary;
	color: $sura_primary;

	&__icon {
		stroke: $sura_primary;
		fill: $sura_primary;
	}
}

.assa {
	border: 1px solid $assa_text;
	color: $assa_text;

	&__icon {
		&.chevron {
			width: 10px !important;
		}
		stroke: $assa_text;
		fill: $assa_text;
	}
}

.csmBackground {
	background-color: $csm_backgroundColor;
}

.assaBackground {
	background-color: $assa-bg;
}
