@import '../../variables';

.uploadPhoto {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.uploadZone {
		width: 80%;
		margin: 1rem;
		padding: 10% 5% 10%;
		align-self: center;
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='35' ry='35' stroke='%23D8D6D6FF' stroke-width='4' stroke-dasharray='15' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
		border-radius: 35px;
	}
	.uploadIcon {
		background-color: $lafise_primary;
		text-align: center;
		padding: 10px 7px 7px;
		border-radius: 35px;
		width: 31px;
		margin: 0% 40%;
		img {
			width: 23px;
		}
	}
	#image-upload {
		display: none;
	}
	.uploadText {
		display: block;
		text-align: center;
		font-size: 91%;
		margin-top: 14%;
	}
	.gallery-photos {
		margin-top: 10%;
		.image-container {
			margin: 3% 11%;
			clear: both;
		}
		.image {
			width: 12rem;
			height: 12rem;
			border-radius: 12px;
			float: right;
			margin-top: 5%;
		}
		.remove {
			height: 15px;
			width: 15px;
			background-color: #e7ebec;
			padding: 3px;
			border-radius: 30px;
			float: right;
			margin-left: 4%;
			margin-top: 5%;
		}
	}
	.confirm-button {
		margin-top: 0px;
	}
}

.oceanica {
	.uploadPhoto {
		.uploadIcon {
			background-color: $oceanica_primary;
		}
	}
}

.qualitas {
	.uploadPhoto {
		.uploadIcon {
			background-color: $qualitas_secondary;
		}
	}
}

.assa {
	.uploadPhoto {
		.uploadIcon {
			background-color: $assa_secondary;
		}
	}
}

.connect {
	.uploadPhoto {
		.uploadIcon {
			background-color: $primary;
		}
	}
}
