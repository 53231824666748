@import '../../variables';

.text-input {
	display: flex;
	position: relative;
	flex-direction: column;
	flex: 1;
	margin: 10px 0;

	label {
		position: absolute;
		color: $dark-gray;
		pointer-events: none;
		left: 20px;
		top: 15px;
		transition: 0.2s ease all;
		font-size: 18px;
	}

	input {
		padding: 13px;
		border: 1px solid $dark-gray;
		border-radius: 5px;
		box-shadow: 0;
		background-color: $kinda-white;
		margin: 0 8px;
		-webkit-appearance: none;
	}
	input:focus {
		border-color: $primary;
		outline: none;
	}

	input:focus ~ label,
	input:valid ~ label,
	input:read-only ~ label {
		padding: 0 5px;
		top: -7px;
		left: 10px;
		font-size: 12px;
		background: $kinda-white;
	}
	input:disabled ~ label {
		background: $light-gray;
	}

	span {
		padding: 5px 12px;
		font-size: 80%;
	}
}
.has-error {
	label {
		color: $danger;
	}
	input {
		border-color: $danger;
	}
	span {
		color: $danger;
	}
}

.sura .text-input {
	input:focus {
		border-color: $sura_primary;
	}
	input:focus ~ label,
	input:valid ~ label,
	input:read-only ~ label {
		background-color: $light;
	}
}

.csm .text-input {
	input:focus {
		border-color: $csm_primary;
	}
	input:focus ~ label,
	input:valid ~ label,
	input:read-only ~ label {
		background: $kinda-white;
		background: linear-gradient(
			0deg,
			$kinda-white 0%,
			$kinda-white 45%,
			$csm_backgroundColor 45%,
			$csm_backgroundColor 100%
		);
	}
	input:disabled ~ label {
		background: $light-gray;
		background: linear-gradient(
			0deg,
			$light-gray 0%,
			$light-gray 45%,
			$csm_backgroundColor 45%,
			$csm_backgroundColor 100%
		);
	}
}

.lafise .text-input {
	input:focus {
		border-color: $lafise_primary;
	}
	input:focus ~ label,
	input:valid ~ label,
	input:read-only ~ label {
		background-color: $light;
	}
}

.oceanica .text-input {
	input:focus {
		border-color: $oceanica_primary;
	}
	input:focus ~ label,
	input:valid ~ label,
	input:read-only ~ label {
		background-color: $light;
	}
}

.qualitas .text-input {
	input:focus {
		border-color: $qualitas_primary;
	}
	input:focus ~ label,
	input:valid ~ label,
	input:read-only ~ label {
		background-color: $light;
	}
}

.assa .text-input {
	input:focus {
		border-color: $assa_primary;
	}
	input:focus ~ label,
	input:valid ~ label,
	input:read-only ~ label {
		background: $kinda-white;
		background: linear-gradient(0deg, $kinda-white 0%, $kinda-white 45%, $assa-bg 45%, $assa-bg 100%);
	}
	input:disabled ~ label {
		background: $light-gray;
		background: linear-gradient(0deg, $light-gray 0%, $light-gray 45%, $assa-bg 45%, $assa-bg 100%);
	}
}
