@import '../../variables';

.direct-deposit {
	.container {
		flex: 1;
		margin: 0 $padding;
		h2 {
			font-size: 16px;
			margin-top: 0;
		}
	}
	.direct-deposit-input {
		display: flex;
		position: relative;
		flex-direction: column;
		flex: 1;
		margin: 10px 0;
		label {
			position: absolute;
			color: $dark-gray;
			pointer-events: none;
			left: 10px;
			top: 15px;
			transition: 0.2s ease all;
			font-size: 15px;
		}
		input {
			padding: 13px;
			border: 1px solid $dark-gray;
			border-radius: 6px;
			box-shadow: 0;
			background-color: $kinda-white;
			-webkit-appearance: none;
		}
		input:focus ~ label,
		input:valid ~ label {
			top: -7px;
			left: 10px;
			color: $dark-gray;
			font-size: 12px;
			background-color: $csm_backgroundColor;
		}
		.container {
			flex: 1;
			margin: 0;
		}
		.selectedArea {
			margin: 0rem 0rem 0.7rem;
			label {
				margin-bottom: 0px;
			}
		}
	}
	.has-error {
		label {
			color: $danger;
		}
		input {
			border-color: $danger;
		}
	}
	#btnSubmit {
		display: none;
	}
	p {
		font-size: 0.8rem;
		text-align: justify;
	}
}


.csm .direct-deposit {
	background-color: $csm_backgroundColor;
}
